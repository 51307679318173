import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom
import logo from './xhbt-white.png'; // Import the image
import ArtistCollection from './ArtistCollection';

const Home = () => {
  return (
    <Container>
      <Box textAlign="center" mt={4}>
        <img src={logo} alt="XHBT Logo" style={{ width: '150px', marginBottom: '20px' }} />

        {/* Commission form section with primary background color */}
        <Box py={4} px={2} bgcolor="primary.main" color="secondary.main" borderRadius={5} textAlign="center" marginTop="5em" marginBottom="10em" padding="2em">
          <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold', letterSpacing: '0.02em', color: 'white' }}>
            Call for Unreleased Tracks for Liminal Compilation
          </Typography>
          <Typography variant="body1" gutterBottom color="text.primary" sx={{ fontSize: '1.25rem' }}>
            XHBT is curating a liminal album and invites artists to submit unreleased tracks. Accepted submissions will receive an equal share of the album’s revenue.
          </Typography>
          <Box mt={2}>
            <Button 
              component="a" 
              href="https://tally.so/r/mVMBG6" 
              target="_blank" 
              rel="noopener noreferrer" 
              variant="contained" 
              color="secondary"
            >
              Submit Your Track
            </Button>
          </Box>
        </Box>

        {/* Existing content */}
        <Typography variant="h1" align="center" gutterBottom mt={4} color="text.primary">
          COMMISSION MORE ART
        </Typography>
        <Typography variant="body1" align="center" gutterBottom color="text.secondary">
          XHBT® enables professional visual, musical, and literary artists to receive paid commissions from institutions and patrons worldwide.
        </Typography>

        <Box mt={4}>
          <ArtistCollection />
        </Box>
      </Box>
    </Container>
  );
};

export default Home;