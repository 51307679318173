import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#d11e98',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '4.5rem',
      fontWeight: 'black',
      margin: '20px 0',
      padding: '0 20px',
      letterSpacing: '-0.025em',
      lineHeight: '0.9',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      margin: '10px 0',
      padding: '0 20px',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f4f4f4',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Prevent text from auto capitalizing
        },
        containedPrimary: {
          color: '#ffffff', // Button text color for contained primary variant
        },
        text: {
          color: '#000000', // Default text color for text variant (used in nav buttons)
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d11e98',
    },
    secondary: {
      main: '#222222',
    },
    background: {
      default: '#121212', // Dark background
      paper: '#1d1d1d', // Darker paper color for components like cards
    },
    text: {
      primary: '#ffffff', // White text color for better readability in dark mode
      secondary: '#999999',
    },
  },
  typography: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '4.5rem',
      fontWeight: 'black',
      margin: '20px 0',
      padding: '0 20px',
      letterSpacing: '-0.025em',
      lineHeight: '0.9',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      margin: '10px 0',
      padding: '0 20px',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Prevent text from auto capitalizing
        },
        containedPrimary: {
          color: '#ffffff', // Button text color for contained primary variant
        },
        text: {
          color: '#ffffff', // Default text color for text variant in dark mode
        },
      },
    },
  },
});